<template>
	<div id="closeOutDoc" ref="closeOutDoc" style="width: 815px; height: 1056px; padding: 25px 70px 20px;">
		<div class="row" style="display: flex; align-items: center;">
			<div>
				<img :src="layoutConfig('self.logo.larger')" alt="ITC Global Logo" style="width:144px;" />
			</div>
			<div class="flex-grow-1 text-center">
				<h1 class="display-4 font-weight-bold" style="display:inline; font-variant: small-caps;">Service Close Out Document</h1>
			</div>
		</div>
		<div class="d-flex flex-column justify-content-between" style="height: 952px;">
			<div class="row my-3" style="display: flex; justify-content: space-between;">
				<div><b>Doc ID:</b> ITC-INT-CL-4969</div>
				<div><b>Completed By: </b>{{ fields.username }}</div>
				<div><b>NOC Approval By: </b>{{ fields.nocUser }}</div>
			</div>
			<div class="row">
				<table class="table-bordered" style="width: 705px;">
					<colgroup>
						<col style="width: 40%;" />
					</colgroup>
					<tr>
						<th class="text-right pr-3">Client</th>
						<td style="padding: 0 5px 0;">{{ fields.client }}</td>
					</tr>
					<tr>
						<th class="text-right pr-3">Site</th>
						<td style="padding: 0 5px 0;">{{ fields.site }}</td>
					</tr>
					<tr>
						<th class="text-right pr-3">Commissioned Date</th>
						<td style="padding: 0 5px 0;">{{ fields.commissionedDate }}</td>
					</tr>
					<tr>
						<th class="text-right pr-3">Handover to Operations Date</th>
						<td style="padding: 0 5px 0;">{{ fields.handoverDate }}</td>
					</tr>
					<tr>
						<th class="text-right pr-3">Sharepoint Link</th>
						<td style="padding: 0 5px 0;">{{ fields.sharepointLink }}</td>
					</tr>
				</table>
			</div>

			<div class="mt-2" style="margin: 0 -12.5px" id="eng-checklist">
				<h4>Engineering</h4>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.commissioned" class="mr-2" />
					<label> All Customer Services commissioned (Voice, Data, etc.) and verified with onsite contact</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.configSharepoint" class="mr-2" />
					<label> All configurations uploaded to Sharepoint. Copy and paste link above</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.drawingsSharepoint" class="mr-2" />
					<label> All drawings uploaded to Sharepoint. Copy and paste link above</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.solarwinds" class="mr-2" />
					<label> All offshore devices added to Solarwinds</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.solarwindsSnmp" class="mr-2" />
					<label> All network connection points in Solarwinds have the proper SNMP monitoring enabled</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.ipam" class="mr-2" />
					<label> All IPs recorded in IPAM</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.tacacs" class="mr-2" />
					<label> All offshore devices configured for TACACS where possible</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.passwords" class="mr-2" />
					<label> All local passwords recorded on Sharepoint for equipment without TACACS</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.cisco" class="mr-2" />
					<label> All Cisco devices added to NCM (Network Configuration Manager)</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.notification" class="mr-2" />
					<label> Completion notification sent to NOC Manager and Project Manager</label>
				</div>
			</div>
			<div v-if="showNocChecklist" class="mt-3" style="margin: 0 -12.5px" id="noc-checklist">
				<h4>NOC</h4>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.portal" class="mr-2" />
					<label> All relevant monitoring ports added to site page on portal</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.portalAccount" class="mr-2" />
					<label> Portal account has been set up or site has been added to existing customer portal</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.commissionComplete" class="mr-2" />
					<label> Commissioning has been completed successfully and Portal Commissioning Report has been submitted</label>
				</div>
				<div class="d-flex align-items-center">
					<input type="checkbox" v-model="fields.ticketing" class="mr-2" />
					<label> Ensure site is built out in ticketing platform</label>
				</div>
				<div class="d-flex">
					<input type="checkbox" v-model="fields.notifyList" class="mr-2 mt-1" />
					<label> Ensure proper customer notifications have been obtained and have been submitted to IT to implement notification list</label>
				</div>
				<div class="d-flex">
					<input type="checkbox" v-model="fields.netNotifier" class="mr-2 mt-1" />
					<label> Populate network notification tool for planned maintenance/weather notifications with customer notification contacts</label>
				</div>
				<div class="d-flex">
					<input type="checkbox" v-model="fields.voip" class="mr-2 mt-1" />
					<label>Ensure that VoIP account has been linked to NOC Portal and Customer Portal if the client is using ITC provided voice services</label>
				</div>
				<div class="d-flex">
					<input type="checkbox" v-model="fields.proactiveAlerts" class="mr-2 mt-1" />
					<label> Once installation is complete and service is “Active”, ensure proactive monitoring/alerting is enabled, if applicable</label>
				</div>
				<div class="d-flex">
					<input type="checkbox" v-model="fields.sharepointReview" class="mr-2 mt-1" />
					<label>Review Sharepoint to validate all necessary information needed to support the service is uploaded to Sharepoint site page</label>
				</div>
			</div>
			<div class="row mt-3" style="height: 317px;" v-if="!showNocChecklist">
				<h4>NOC</h4>
				<div class="col-md-12" style="margin-top: 20px; text-align: center; height: 220px;">
					<h3>NOC approval not needed</h3>
				</div>
			</div>
			<div class="row mt-3">
				<div class="caption">
					<h5 class="mb-0">General Notes</h5>
				</div>
				<b-form-textarea class="mt-2" rows="5" v-model="fields.notes"></b-form-textarea>
			</div>
			<div class="my-3" style="text-align: center; font-size: 10px;">
				Page 1 of 1
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
	name: 'SRFCloseout',
	props: {
		fields: {
			type: Object,
			default: function() {
				return {
					username: 'jpalacios',
					nocUser: 'Portal',
					client: 'Test Customer',
					site: 'Test Site',
					modem_type: 'X7',
					antenna_serial_number: '',
				};
			},
		},
	},
	data() {
		return {
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['layoutConfig']),
		showNocChecklist() {
			return this.fields.nocUser !== 'Portal';
		},
	},
	methods: {
		generatePdf() {
			let filename = [this.fields.client, this.fields.site, this.fields.modem_type, this.fields.antenna_serial_number, 'CLOSE_OUT.pdf'];
			filename = filename
				.filter(n => n !== '')
				.join('_')
				.replaceAll(' ', '_')
				.replaceAll('/', '-')
				.replaceAll('\\', '-');

			html2canvas(this.$refs.closeOutDoc, { width: 815, height: 1056 }).then(canvas => {
				let doc = {
					pageMargins: [0, 0, 0, 0],
					pageSize: 'letter',
					content: [
						{
							image: canvas.toDataURL('image/jpeg'),
							width: 611,
						},
					],
				};
				let pdf = pdfMake.createPdf(doc);
				pdf.getBuffer(buffer => {
					this.$http.post('srf/uploadnoc', { srfpdf: buffer, DID: this.fields.modem_DID, filename: filename, username: 'CLOSEOUT' }).then(() => {
						pdf.download(filename);
						this.$emit('done');
					});
				});
			});
		},
	},
	mounted() {
		this.generatePdf();
	},
};
</script>

<style scoped>
label {
	margin: 0px;
}
#eng-checklist > div,
#noc-checklist > div {
	margin-top: 3px;
}
.caption {
	width: 100%;
	background: rgb(226, 225, 225);
	padding: 5px 10px;
}
</style>
